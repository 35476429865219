import React from "react"
import rehypeReact from "rehype-react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Title from "../components/Title"

const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler

const SkillsetPage = props => {
  const htmlAst = props.data.allMarkdownRemark.edges[0].node.htmlAst
  const frontmatter = props.data.allMarkdownRemark.edges[0].node.frontmatter

  return (
    <Layout override="marginStandard">
      <SEO title={frontmatter.title} />
      <div className="marginFocus">
        <Title>{frontmatter.title}</Title>
        <p>{frontmatter.subtitle}</p>
        <div style={styles.bodyText}>{renderAst(htmlAst)}</div>
      </div>
    </Layout>
  )
}

const styles = {
  bodyText: {
    marginBottom: "4.0rem",
  },
  centerText: {
    textAlign: "center",
  },
  marginTop: {
    marginTop: "1.6rem",
  },
  center: {
    justifyContent: "center",
  },
  marginVert: {
    marginBottom: "1.0rem",
  },
}

export default SkillsetPage

export const skillsetQuery = graphql`
  query skillsetQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { frontmatter: { type: { eq: "skillset" } } }
    ) {
      edges {
        node {
          frontmatter {
            type
            title
            subtitle
          }
          fields {
            slug
          }
          htmlAst
        }
      }
    }
  }
`
